//Footer Bottom Fix

body {
	position: relative;
	min-height: 100vh;
}

main {
	padding-bottom: 9rem;
}

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}
